import React, { Fragment, useState } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade';
import { Col, Card, Image, ButtonToolbar, Button, Modal } from 'react-bootstrap'

const ServiceCard = ({ data, facility_slug }) => {
	const [show, setShow] = useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const clinic_services = data.clinic_services === [] || data.clinic_services === undefined ? [] : data.clinic_services.filter(e => e.clinic.facility_slug === facility_slug)
	const facility_services = data.facility_services === [] || data.facility_services === undefined ? [] :  data.facility_services.filter(e => e.facility_slug === facility_slug)

	const { avatar } = facility_services !== undefined && facility_services[0] !== undefined && facility_services[0].avatar !== undefined ? facility_services[0] : { avatar: null } 

  const [newData] = useState(handelData(avatar))

  function handelData(avatar){
    return Object.assign(data, {services_avatar: avatar})
  }

	return (
		<Fragment>
      <Col sm={12} md={12} lg={4} className="my-3 w-100">
        <Card className="w-100 service-vertical-card">
          <Card.Body className="d-flex flex-column justify-content-center align-items-center w-100">
            <Image src={(data.avatar || avatar) || "https://s3-ap-southeast-1.amazonaws.com/seeyoudoc-mobile/version+3/image.svg"} alt="images-avatar" className="rounded-circle avatar-mmd mb-3" onError={(e)=>{e.target.onerror = null; e.target.src="https://s3-ap-southeast-1.amazonaws.com/seeyoudoc-mobile/version+3/image.svg"}}/>
            <div className="mt-auto d-flex flex-column align-items-center">
              <h6 className="font-weight-bold ellipsis-1 text-center w-100">{data.name}</h6>
              <div className="text-truncate justify-content-center w-100">
                { data.facility_services.slice(0, 1).map((fs, i) => {
                  return (
                    <Fragment key={i}>
                      {fs.departments.length > 1? 
                        <p className="text-muted text-truncate  mx-w-445 mb-1">
                          {fs.departments.length} Departments
                        </p>
                        :  
                        <p className="text-muted text-truncate  mx-w-445 mb-1">
                          {fs.departments.length} Department
                        </p>
                      }
                      {/* {fs.departments.slice(0, 1).map((dept, e) => 
                          <p className="text-muted text-truncate  mx-w-445 mb-1" key={e}>dsdsd{dept.name}</p>
                        )}*/}
                      </Fragment>
                  )
                })
                }
              </div>
              <ButtonToolbar className="d-flex flex-row justify-content-between mt-3 w-100">
                <Link 
                  className={clinic_services.length > 0 ? "btn btn-outline-primary text-nowrap mr-2 d-flex align-items-center justify-content-center w-46" :  "btn btn-outline-primary text-nowrap mr-2 d-flex align-items-center justify-content-center w-100"}
                  to={`/service/${data.slug}`}
                  state={newData}>
                  SCHEDULE
                </Link>
                { clinic_services.length > 0 ?
                    <Fragment>
                      <Button variant="primary" onClick={handleShow} className="btn btn-primary text-nowrap w-46">
                        BOOK
                      </Button>
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Choose a clinic</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="clinic-modal">
                          {
                            clinic_services.map((e, i) => 
                              <div key={i} className="d-flex flex-row align-items-center py-2 list-item border-bottom">
                                <Image src={e.clinic.avatar || "https://s3-ap-southeast-1.amazonaws.com/seeyoudoc-mobile/version+3/image.svg"} alt="images-avatar" className="image-xxs" onError={(e)=>{e.target.onerror = null; e.target.src="https://s3-ap-southeast-1.amazonaws.com/seeyoudoc-mobile/version+3/image.svg"}} />
                                <div className="d-flex flex-column ml-3">
                                  <a target="_blank" rel="noopener noreferrer" className="h6 mb-0 text-decoration-none" href={process.env.SYD_URL + "/book-appointment/" + e.clinic.slug + "/service/" + e.id}>{e.clinic.name}</a>
                                  <p class="text-muted mb-0">{e.clinic.facility_address}</p>
                                </div>
                              </div>
                            )
                          }
                        </Modal.Body>
                      </Modal>
                    </Fragment>
                    : <Fragment /> 
                }
              </ButtonToolbar>
            </div>
          </Card.Body>
        </Card>
			</Col>
		</Fragment>
	)
}

export default ServiceCard
