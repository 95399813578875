import React from 'react'
import { Card, Form } from 'react-bootstrap'
import { MultiDropdownList, DataSearch } from '@appbaseio/reactivesearch'

const defaultQuery = (slug) => {
  return {
    "query": {
      "match_phrase_prefix": {
        "facility_services.facility_slug": {
          "query": slug
        }
      }
    }
  }
}

const Filter = ({slug}) => {
  return (
    <Card body>
      <h6 className="font-weight-bold mb-4">FILTER</h6>
      <Form>
        <Form.Group controlId="SearchDepartment">
          <Form.Label className="font-weight-bold">Search</Form.Label>
          <DataSearch
            componentId="SearchFilter"
            dataField={["name", "departments"]}
            showIcon={false}
            placeholder="Search specific services"
            defaultQuery={() => defaultQuery(slug || "pldt")}
            className="w-100 pr-1"
          />
        </Form.Group>
        <Form.Group controlId="SearchDepartment">
          <Form.Label className="font-weight-bold">Departments</Form.Label>
          <MultiDropdownList
            defaultQuery={() => defaultQuery(slug || "pldt")}
            placeholder="Select Departments"
            componentId="SearchDepartments"
            dataField="facility_services.departments.name.keyword"
            size={1000}
          />
        </Form.Group>
      </Form>
    </Card>
  )
}

export default Filter
