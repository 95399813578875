import React, { Component } from 'react'
import { Home } from 'react-feather'
import { SEO, Layout, HMOSlider } from '../../components/common'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import { ReactiveBase, ReactiveList } from '@appbaseio/reactivesearch'
import Filter from './Filter'
import ServiceCard from './ServiceCard'

class OurService extends Component {
  renderData(data, slug) {
    return <ServiceCard key={data._id} data={data} facility_slug={slug} />
  }

  defaultQuery(slug) {
    return {
      "query": {
        "match_phrase_prefix": {
          "facility_services.facility_slug": {
            "query": slug
          }
        }
      }
    }
  }

  render() {
    // const { ourServicesPage } = this.props.pageContext
    const data = this.props.pageContext
    const { slug, favicon } = data.topNav
    return (
      <>
        <SEO title="Our Services" favicon={favicon} />
      <Layout activeNav='our-services' data={data.topNav}>
        <Container fluid className="my-4 md-mt-10 sm-mt-7">
          <Container className="my-4">
            <Breadcrumb className="breadcrumb-wrapper mx-0">
              <Breadcrumb.Item href="/">
                <Home className="text-violet mr-2" size={14} />
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Search Service</Breadcrumb.Item>
            </Breadcrumb>
          </Container>

          <ReactiveBase
            app="services"
            url={process.env.ES_URL}
          >
            <section className="reactive-search">
              <Row>
                <Col xs={12} sm={12} md={4} lg={2} xl={2} className="d-none d-md-block my-3">
                  <Filter slug={slug}/> 
                </Col>
                <Col xs={12} sm={12} md={8} lg={9} xl={9}>
                  <Row className="d-flex justify-content-center align-items-center h-100">
                    <ReactiveList
                      componentId="ResultList"
                      dataField="name"
                      className="result-list-container custom-result-list"
                      defaultQuery={() => this.defaultQuery(slug || "pldt")}
                      react={{
                        and: ["SearchFilter", "SearchDepartments"]
                      }}
                      renderItem={ data => this.renderData(data, slug)}
                      innerClass={{
                        listItem: 'row',
                      }}
                      renderNoResults={() =>
                          <div className="no-data-available">
                            <div className="data-container">
                              <img src="https://s3-ap-southeast-1.amazonaws.com/seeyoudoc-mobile/version+3/user.svg" alt="user" className="img-small" />
                              <p className="text-dark">There are no data available. </p>
                            </div>
                          </div>
                      } 
                    />
                </Row>
              </Col>
            </Row>
          </section>
        </ReactiveBase>
      </Container>

      <HMOSlider data={data.accreditedHmos} />
    </Layout>
      </>
    )
  }
}

export default OurService 
